@use '@shared/util-styling/mixins';

$transition: all 0.2s ease-in;

@mixin image {
  height: 156px;
  position: relative;
  transition: $transition;
  width: 100%;

  @include mixins.breakpoint('medium') {
    height: 280px;
  }

  &::after {
    background-color: var(--overlay-primary-fill);
    border-radius: inherit;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: $transition;
    width: 100%;
  }

  &--is-locked {
    &::after {
      background-color: var(--overlay-secondary-fill);
      opacity: 1;
    }
  }
}

@mixin icon-styles($icon-size) {
  height: $icon-size;
  transform: translate(-($icon-size / 2), -($icon-size / 2));
  width: $icon-size;
}

.image-container {
  @include image;

  &__icon {
    @include icon-styles(40px);

    color: var(--overlay-text); // todo use token
    left: 50%;
    opacity: 1;
    position: absolute;
    top: 50%;
    transition: $transition;
    z-index: 1;

    @include mixins.breakpoint('medium') {
      @include icon-styles(56px);
    }
  }

  &__call-to-action {
    color: var(--overlay-text); // todo use token
    font: var(--mdrt-title-4);
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(0, -12px);
    transition: $transition;
    width: 100%;
    z-index: 1;

    @include mixins.breakpoint('medium') {
      font: var(--mdrt-title-2);
    }
  }
}

.image {
  @include image;

  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  object-fit: cover;
  object-position: center center;
}

.number {
  bottom: var(--spacing-20);
  color: var(--mdrt-color-text-on-dark-primary);
  height: 48px;
  position: absolute;
  right: calc(var(--spacing-8) * -1);
  transition: $transition;
  width: 76px;
  z-index: 1;

  @include mixins.breakpoint('medium') {
    height: 76px;
    width: 119px;
  }
}

:global(.card-with-hover-effect:hover),
:global(.card-with-hover-effect:focus) {
  .image,
  .image-container {
    height: 92px;
    transition: $transition;

    @include mixins.breakpoint('medium') {
      height: 204px;
    }

    &::after {
      opacity: 1;
    }

    &--is-locked {
      &::after {
        background-color: var(--overlay-tertiary-fill);
      }
    }
  }

  .image-container {
    &__icon {
      opacity: 0;
      transition: $transition;
    }

    &__call-to-action {
      opacity: 1;
      transition: $transition;
    }
  }

  .number {
    bottom: calc(6px * -1);
    transition: $transition;
  }
}
